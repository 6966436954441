import { Select, SelectProps } from "antd"
import { FC } from "react"
import { ReactComponent as ArrowDownSVG } from "../../assets/icons/ArrowDownSelect.svg"
import "./LanguageSelector.less"

type Props = {
  onSelect?: () => void
}

export const LanguageSelector: FC<Props> = ({ onSelect }) => {
  const options: SelectProps["options"] = [
    { label: "EN", value: "en" },
    { label: "AR", value: "ar" },
  ]

  return (
    <div className={"selector-container"}>
      <Select
        onSelect={onSelect}
        suffixIcon={<ArrowDownSVG />}
        rootClassName={"selector"}
        options={options}
        bordered={false}
        defaultValue={"EN"}
      />
    </div>
  )
}
