import { FC } from "react";
import { Link } from "react-router-dom";
import './FooterLink.less'

type Props = {
  item: Maybe<ComponentUiLink>
}

export const FooterLink: FC<Props> = ({ item }) => (
  <div className={'footer-link-wrapper'}>
    <Link to={item?.url || ''} className={'footer-link'}>
      {item?.title}
    </Link>
  </div>
)