import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;

      export type PossibleTypesResultData = {
  "possibleTypes": {
    "GenericMorph": [
      "CommonQuestion",
      "ComponentDataEntry",
      "ComponentDataSet",
      "ComponentUiCard",
      "ComponentUiGrid",
      "ComponentUiHeadline",
      "ComponentUiLink",
      "ComponentUiParagraph",
      "ComponentUiSection",
      "ComponentUiSectionWithCards",
      "ComponentUiTab",
      "ComponentUiText",
      "ContactUs",
      "Home",
      "Layout",
      "NewsletterSubscription",
      "PricesAndService",
      "ReactIconsIconlibrary",
      "Service",
      "Social",
      "UploadFile",
      "UploadFolder",
      "UsersPermissionsPermission",
      "UsersPermissionsRole",
      "UsersPermissionsUser"
    ]
  }
};
      const result: PossibleTypesResultData = {
  "possibleTypes": {
    "GenericMorph": [
      "CommonQuestion",
      "ComponentDataEntry",
      "ComponentDataSet",
      "ComponentUiCard",
      "ComponentUiGrid",
      "ComponentUiHeadline",
      "ComponentUiLink",
      "ComponentUiParagraph",
      "ComponentUiSection",
      "ComponentUiSectionWithCards",
      "ComponentUiTab",
      "ComponentUiText",
      "ContactUs",
      "Home",
      "Layout",
      "NewsletterSubscription",
      "PricesAndService",
      "ReactIconsIconlibrary",
      "Service",
      "Social",
      "UploadFile",
      "UploadFolder",
      "UsersPermissionsPermission",
      "UsersPermissionsRole",
      "UsersPermissionsUser"
    ]
  }
};
      export default result;
    
export const ParagraphFragmentDoc = gql`
    fragment Paragraph on ComponentUiParagraph {
  id
  value
}
    `;
export const LinkFragmentDoc = gql`
    fragment Link on ComponentUiLink {
  id
  title
  url
  target
}
    `;
export const FileFragmentDoc = gql`
    fragment File on UploadFileEntity {
  id
  attributes {
    previewUrl
    alternativeText
    url
  }
}
    `;
export const CardFragmentDoc = gql`
    fragment Card on ComponentUiCard {
  id
  title
  subtitle
  description
  buttonUrl
  media {
    data {
      ...File
    }
  }
}
    `;
export const SectionFragmentDoc = gql`
    fragment Section on ComponentUiSection {
  id
  button {
    ...Link
  }
  heading {
    ...Card
  }
}
    `;
export const HeadlineFragmentDoc = gql`
    fragment Headline on ComponentUiHeadline {
  id
  title
  subtitle
}
    `;
export const SectionWithCardsFragmentDoc = gql`
    fragment SectionWithCards on ComponentUiSectionWithCards {
  id
  heading {
    ...Headline
  }
  sectionCard {
    ...Card
  }
  sectionImage {
    data {
      ...File
    }
  }
  button {
    ...Link
  }
}
    `;
export const EntryFragmentDoc = gql`
    fragment Entry on ComponentDataEntry {
  id
  key
  value
}
    `;
export const ServiceFragmentDoc = gql`
    fragment Service on Service {
  name
  description
  item(pagination: {limit: 100}) {
    ...Entry
  }
  media {
    data {
      ...File
    }
  }
  slug
}
    `;
export const SocialFragmentDoc = gql`
    fragment Social on Social {
  url
  icon {
    data {
      ...File
    }
  }
}
    `;
export const TabFragmentDoc = gql`
    fragment Tab on ComponentUiTab {
  id
  name
  pane {
    ...Card
  }
}
    `;
export const NewsSubscriptionDocument = gql`
    mutation newsSubscription($input: NewsletterSubscriptionInput!) {
  createNewsletterSubscription(data: $input) {
    data {
      attributes {
        email
      }
    }
  }
}
    `;
export type NewsSubscriptionMutationFn = Apollo.MutationFunction<NewsSubscriptionMutation, NewsSubscriptionMutationVariables>;
export function useNewsSubscriptionMutation(baseOptions?: Apollo.MutationHookOptions<NewsSubscriptionMutation, NewsSubscriptionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<NewsSubscriptionMutation, NewsSubscriptionMutationVariables>(NewsSubscriptionDocument, options);
      }
export type NewsSubscriptionMutationHookResult = ReturnType<typeof useNewsSubscriptionMutation>;
export type NewsSubscriptionMutationResult = Apollo.MutationResult<NewsSubscriptionMutation>;
export const ContactUsDocument = gql`
    query contactUs {
  contactUs {
    data {
      attributes {
        heading {
          ...Headline
        }
        media {
          data {
            ...File
          }
        }
        socials {
          data {
            attributes {
              ...Social
            }
          }
        }
        fields {
          ...Entry
        }
      }
    }
  }
}
    ${HeadlineFragmentDoc}
${FileFragmentDoc}
${SocialFragmentDoc}
${EntryFragmentDoc}`;
export function useContactUsQuery(baseOptions?: Apollo.QueryHookOptions<ContactUsQuery, ContactUsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ContactUsQuery, ContactUsQueryVariables>(ContactUsDocument, options);
      }
export function useContactUsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ContactUsQuery, ContactUsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ContactUsQuery, ContactUsQueryVariables>(ContactUsDocument, options);
        }
export type ContactUsQueryHookResult = ReturnType<typeof useContactUsQuery>;
export type ContactUsLazyQueryHookResult = ReturnType<typeof useContactUsLazyQuery>;
export type ContactUsQueryResult = Apollo.QueryResult<ContactUsQuery, ContactUsQueryVariables>;
export const HomeDocument = gql`
    query home {
  home {
    data {
      attributes {
        hero {
          ...Section
        }
        whyChooseCarely {
          ...SectionWithCards
        }
        trustedOrganizations {
          ...SectionWithCards
        }
        weProvide {
          ...SectionWithCards
        }
        howItWorks {
          ...SectionWithCards
        }
        learnMore {
          ...SectionWithCards
        }
        saveHours {
          ...Section
        }
        common_questions {
          data {
            attributes {
              qna {
                ...Entry
              }
            }
          }
        }
      }
    }
  }
}
    ${SectionFragmentDoc}
${LinkFragmentDoc}
${CardFragmentDoc}
${FileFragmentDoc}
${SectionWithCardsFragmentDoc}
${HeadlineFragmentDoc}
${EntryFragmentDoc}`;
export function useHomeQuery(baseOptions?: Apollo.QueryHookOptions<HomeQuery, HomeQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<HomeQuery, HomeQueryVariables>(HomeDocument, options);
      }
export function useHomeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<HomeQuery, HomeQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<HomeQuery, HomeQueryVariables>(HomeDocument, options);
        }
export type HomeQueryHookResult = ReturnType<typeof useHomeQuery>;
export type HomeLazyQueryHookResult = ReturnType<typeof useHomeLazyQuery>;
export type HomeQueryResult = Apollo.QueryResult<HomeQuery, HomeQueryVariables>;
export const LayoutDocument = gql`
    query layout {
  layout {
    data {
      attributes {
        headerMenu {
          ...Link
        }
        footerMenu {
          ...Link
        }
        footerPhoneImage {
          data {
            ...File
          }
        }
        footerTitle
      }
    }
  }
}
    ${LinkFragmentDoc}
${FileFragmentDoc}`;
export function useLayoutQuery(baseOptions?: Apollo.QueryHookOptions<LayoutQuery, LayoutQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<LayoutQuery, LayoutQueryVariables>(LayoutDocument, options);
      }
export function useLayoutLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<LayoutQuery, LayoutQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<LayoutQuery, LayoutQueryVariables>(LayoutDocument, options);
        }
export type LayoutQueryHookResult = ReturnType<typeof useLayoutQuery>;
export type LayoutLazyQueryHookResult = ReturnType<typeof useLayoutLazyQuery>;
export type LayoutQueryResult = Apollo.QueryResult<LayoutQuery, LayoutQueryVariables>;
export const MeDocument = gql`
    query me {
  me {
    id
  }
}
    `;
export function useMeQuery(baseOptions?: Apollo.QueryHookOptions<MeQuery, MeQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<MeQuery, MeQueryVariables>(MeDocument, options);
      }
export function useMeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MeQuery, MeQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<MeQuery, MeQueryVariables>(MeDocument, options);
        }
export type MeQueryHookResult = ReturnType<typeof useMeQuery>;
export type MeLazyQueryHookResult = ReturnType<typeof useMeLazyQuery>;
export type MeQueryResult = Apollo.QueryResult<MeQuery, MeQueryVariables>;
export const PricesAndServicesDocument = gql`
    query pricesAndServices {
  pricesAndService {
    data {
      attributes {
        title
        services {
          data {
            attributes {
              ...Service
            }
          }
        }
        card {
          ...Card
        }
      }
    }
  }
}
    ${ServiceFragmentDoc}
${EntryFragmentDoc}
${FileFragmentDoc}
${CardFragmentDoc}`;
export function usePricesAndServicesQuery(baseOptions?: Apollo.QueryHookOptions<PricesAndServicesQuery, PricesAndServicesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<PricesAndServicesQuery, PricesAndServicesQueryVariables>(PricesAndServicesDocument, options);
      }
export function usePricesAndServicesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PricesAndServicesQuery, PricesAndServicesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<PricesAndServicesQuery, PricesAndServicesQueryVariables>(PricesAndServicesDocument, options);
        }
export type PricesAndServicesQueryHookResult = ReturnType<typeof usePricesAndServicesQuery>;
export type PricesAndServicesLazyQueryHookResult = ReturnType<typeof usePricesAndServicesLazyQuery>;
export type PricesAndServicesQueryResult = Apollo.QueryResult<PricesAndServicesQuery, PricesAndServicesQueryVariables>;
export const SocialsDocument = gql`
    query socials {
  socials {
    data {
      attributes {
        ...Social
      }
    }
  }
}
    ${SocialFragmentDoc}
${FileFragmentDoc}`;
export function useSocialsQuery(baseOptions?: Apollo.QueryHookOptions<SocialsQuery, SocialsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SocialsQuery, SocialsQueryVariables>(SocialsDocument, options);
      }
export function useSocialsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SocialsQuery, SocialsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SocialsQuery, SocialsQueryVariables>(SocialsDocument, options);
        }
export type SocialsQueryHookResult = ReturnType<typeof useSocialsQuery>;
export type SocialsLazyQueryHookResult = ReturnType<typeof useSocialsLazyQuery>;
export type SocialsQueryResult = Apollo.QueryResult<SocialsQuery, SocialsQueryVariables>;