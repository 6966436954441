import { Col, Row } from "antd";
import { FC } from "react";
import { NavLink } from "react-router-dom";
import './Navigation.less'

type Props = {
  menuItems: ComponentUiLink[]
}

export const Navigation: FC<Props> = ({ menuItems }) => {
  return (
    <Row className={'desktop-navigation-wrapper'}>
      {menuItems && menuItems.map((item, index) => (
        <Col key={index} className={'desktop-navigation-item'}>
          <NavLink  to={item.url} className={'desktop-navigation-item-link'}>
            {item.title || ''}
          </NavLink>
        </Col>
      ))}
    </Row>
  )
}