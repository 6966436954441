import { Col, Divider, Image, Row, Typography } from "antd"
import { FC } from "react"
import { Link } from "react-router-dom"
import './Footer.less'
import { useLayoutQuery, useSocialsQuery } from "src/graphql"
import { FooterLink } from "./FooterLink"
import { ReactComponent as Logo } from '/src/assets/icons/Logo.svg'
import { ReactComponent as WhatsUpLogo } from '/src/assets/icons/WhatsApp.svg'
import { ReactComponent as InstagramLogo } from '/src/assets/icons/Instagram.svg'
import { ReactComponent as AppStoreBadge } from '/src/assets/icons/AppStoreBadge.svg'
import { ReactComponent as GooglePlayBadge } from '/src/assets/icons/GooglePlayBadge.svg'
import { ReactComponent as MadaLogo } from '/src/assets/icons/Mada.svg'
import { ReactComponent as VisaLogo } from '/src/assets/icons/Visa.svg'
import { ReactComponent as MasterLogo } from '/src/assets/icons/Mastercard.svg'
import { ReactComponent as AmericanLogo } from '/src/assets/icons/American Express.svg'
import { FooterForm } from "./FooterForm"
import { useMediaQuery } from "react-responsive"

const Footer: FC = () => {
  const { footerMenu, footerPhoneImage, footerTitle } = useLayoutQuery().data?.layout?.data?.attributes || {}
  const isDesktop = useMediaQuery({ query: '(min-width: 1025px)' })
  const isTablet = useMediaQuery({ query: '(min-width: 768px)' })
  const { data: socialsData } = useSocialsQuery()

  return (
    <div className={'footer-wrapper'}>
      <div className={'footer-inner-container'}>
        <Row justify={"space-between"} className={"footer-first-row"}>
          {isDesktop && <Col>
            <Image height={527} preview={false} src={footerPhoneImage?.data?.attributes?.url} />
          </Col>}

          <Col className={'footer-heading'}>
            <p className={'footer-title'} dangerouslySetInnerHTML={{__html:footerTitle || ''}}/>

            <div className={'footer-heading-bages-group'}>
              <a>
                <GooglePlayBadge />
              </a>

              <a>
                <AppStoreBadge />
              </a>
            </div>
          </Col>
        </Row>

        <Row className={'footer-second-row'}>
          <Col className={'footer-logo-column'} xs={{ span: 24 }} md={{ span: 18 }} lg={{ span: 8 }}>
            <div className={'footer-logo-wrapper'}>
              <Logo style={{ cursor: 'pointer' }} onClick={() => window.location.assign('/')} />
            </div>

            <Typography.Text className={'footer-logo-column-text'}>
              Your Trusted Partner for Exceptional <br />
              Laundry and Dry-Cleaning Services
            </Typography.Text>
            
            <div>
              <WhatsUpLogo 
                onClick={() => window.location.assign(socialsData?.socials?.data[1].attributes?.url || '')} 
                className={'footer-socials-logo whatsup'}
              />

              <InstagramLogo
                onClick={() => window.location.assign(socialsData?.socials?.data[0].attributes?.url || '')}

                className={'footer-socials-logo instagram'}
              />
            </div>
          </Col>

          <Col className={'footer-links-column'} xs={{ span: 24 }} md={{ span: 6 }} lg={{ span: 6 }}>
            {footerMenu?.map((item) => (
              <FooterLink key={item?.id} item={item as Maybe<ComponentUiLink>} />
            ))}
          </Col>

          <Col xs={{ span: 24 }} lg={{ span: 10 }}>
            <Typography.Text className={'footer-form-title'}>
              Subscribe to our newsletter
            </Typography.Text>
            <FooterForm />
          </Col>
        </Row>


        <Row className={'footer-third-row'}>
          <Divider style={{ backgroundColor: '#A3AAB8' }}/>

          <Col className={'footer-third-row-info-links-wrapper'}>
            <Typography.Text style={{ order: isDesktop ? 1 : isTablet ? 1 : 3 }} className={'footer-third-row-info-link'}>
              ©2024 Carely
            </Typography.Text>

            <Link style={{ order: isDesktop ? 2 : 1 }} to={'/terms-of-services'} className={'footer-third-row-info-link'}>
              Terms of Services
            </Link>

            <Link style={{ order: isDesktop ? 3 : 2 }} to={'/terms-of-services'} className={'footer-third-row-info-link'}>
              Privacy Policy
            </Link>

            <div style={{ order: 4 }} className={'payment-methods-wrapper'}>
              <MadaLogo />
              <VisaLogo />
              <MasterLogo />
              <AmericanLogo />
            </div>
          </Col>
        </Row>
      </div>
    </div>
  )
}

export { Footer }
