import { Button, Form, Input, message } from "antd"
import { FC } from "react"
import { ButtonType, CustomButton } from "src/components/ui/CustomButton"
import { useNewsSubscriptionMutation } from "src/graphql"
import "./FooterForm.less"

export const FooterForm: FC = () => {
  const [createNewsLetterSubscription] = useNewsSubscriptionMutation()
  const [form] = Form.useForm()

  const onFinish = async (values: NewsletterSubscriptionInput) => {
    try {
      await createNewsLetterSubscription({
        variables: {
          input: {
            email: values.email,
          },
        },
      })

      form.resetFields()
      message.success("Subscription successful!")
    } catch (error) {
      console.log(`Cant handle querry, error - ${error}`)
      message.error("This email already subscribed")
    }
  }

  return (
    <Form form={form} onFinish={onFinish} validateTrigger={"onSubmit"} className={"footer-form"}>
      <Form.Item label={""} name={"email"} rules={[{ type: "email" }]} className={"footer-email-input"}>
        <Input className={"email-input"} size={"large"} placeholder={"Enter your email address"} />
      </Form.Item>

      <Form.Item className={"footer-email-submit-button"}>
        <CustomButton
          buttonText={"Subscribe"}
          type={ButtonType.UNSET_LIGHT}
          htmlType={"submit"}
          size={"large"}
          style={{ justifyContent: "center" }}
        />
      </Form.Item>
    </Form>
  )
}
