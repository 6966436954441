import { Layout as BaseLayout } from "antd"
import { FC, Suspense } from "react"
import { Outlet } from "react-router-dom"
import { Footer, Header } from "."

const Layout: FC = () => (
  <BaseLayout>
    <Header />
    <BaseLayout>
      <Suspense>
        <Outlet />
      </Suspense>
    </BaseLayout>
    <Footer />
  </BaseLayout>
)

export { Layout }
