import { Button, Divider, Drawer, Row } from "antd"
import { ReactComponent as BurgerButton } from '/src/assets/icons/Burger.svg'
import { ReactComponent as Logo } from '/src/assets/icons/Logo.svg'
import { ReactComponent as ArrowRight } from '/src/assets/icons/ArrowRight.svg'
import { ReactComponent as WhatsappLogo } from '/src/assets/icons/WhatsApp.svg'
import { ReactComponent as InstagramLogo } from '/src/assets/icons/Instagram.svg'
import { FC, Fragment, useState } from "react"
import './MobileNavigation.less'
import { Link } from "react-router-dom"
import { ButtonType, CustomButton } from "src/components/ui/CustomButton"
import { LanguageSelector } from "src/components/LanguageSelector"
import { useSocialsQuery } from "src/graphql"

type Props = {
  menuItems: ComponentUiLink[]
}

export const MobileNavigation: FC<Props> = ({ menuItems }) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false)
  const { data: socialsData } = useSocialsQuery()
  const handleOpenMenu = () => (
    setIsMenuOpen(prevState => !prevState)
  )

  const handleSelect = () => {
    handleOpenMenu()
  }

  return (
    <Fragment>
      <Row align={'middle'} className={'burger-button-wrapper'}>
        <Button onClick={handleOpenMenu} className={'burger-button'}>
          <BurgerButton />
        </Button>
      </Row>
      <Drawer 
        open={isMenuOpen} 
        onClose={handleOpenMenu}
        rootClassName={'drawer'}
        title={<Logo className={'drawer-logo'} />}
      >
        <ul className={'list'}>
          {menuItems && menuItems.map((item, index) => {
            const isLastItem = index === menuItems.length -1
            return (
              <li key={item.id} className={'list-item'} onClick={handleOpenMenu}>
                <Link to={item.url} className={'list-item-link'}>
                  {item.title}
                  <ArrowRight />
                </Link>
                {!isLastItem && <Divider />}
              </li>
            )
          })}
        </ul>

        <div className={'button-wrapper'}>
          <CustomButton onClick={handleOpenMenu} type={ButtonType.BLUE} buttonText={'Order Now'} icon={<ArrowRight />} />
        </div>

        <div className={'drawer-bottom-section'}>
          <LanguageSelector onSelect={handleSelect} />
          
          <div className={'logos-wrapper'}>
            <a href={socialsData?.socials?.data[0].attributes?.url || ''} target={'_blank'} rel={"noreferrer"}>
              <WhatsappLogo className={'social-logo'} />  
            </a>

            <a href={socialsData?.socials?.data[1].attributes?.url || ''} target={'_blank'} rel={"noreferrer"}> 
              <InstagramLogo className={'social-logo'} />        
            </a>
          </div>
        </div>
      </Drawer>
    </Fragment>
  )
}