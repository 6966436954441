import { Button } from "antd";
import { SizeType } from "antd/es/config-provider/SizeContext";
import { CSSProperties, FC } from "react";
import './CustomButton.less'

export enum ButtonType {
  BLUE = 'blue',
  UNSET_LIGHT = 'unset-light',
  UNSET_DARK = 'unset-dark'
}

type Props = {
  type: ButtonType
  classname?: string
  style?: CSSProperties
  size?: SizeType
  buttonText: Maybe<string>
  htmlType?: "button" | "submit" | "reset" | undefined 
  fullWitdh?: boolean
  icon?: React.ReactNode
  href?: string
  onClick?: (React.MouseEventHandler<HTMLAnchorElement> & React.MouseEventHandler<HTMLButtonElement>) | undefined
}

export const CustomButton: FC<Props> = ({ onClick, href, fullWitdh, buttonText, type, style, classname, size, htmlType, icon }) => {
  return (
    <Button onClick={onClick} href={href} icon={icon} shape={'default'} htmlType={htmlType} style={style} size={size} className={`${classname} custom-button custom-button-type-${type} ${fullWitdh ? 'fullWidht' : ''}`}>
      {buttonText}
    </Button>
  )
}