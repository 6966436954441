import { Col, Row } from "antd"
import { FC } from "react"
import './Header.less'

import { Navigation } from "../../menu/Navigation"
import { MobileNavigation } from "../../menu/MobileNavigation"
import { ReactComponent as WhatsAppLogo } from '../../../assets/icons/WhatsApp.svg'
import { useLayoutQuery } from "src/graphql"
import { useMediaQuery } from "react-responsive"
import { ReactComponent as Logo } from '/src/assets/icons/Logo.svg'
import { LanguageSelector } from "src/components/LanguageSelector"
import { useNavigate } from "react-router"

const Header: FC<{ renderMenu?: FC }> = () => {
  const { headerMenu } = useLayoutQuery().data?.layout?.data?.attributes || {}
  const isDesktop = useMediaQuery({ query: '(min-width: 1025px)' })
  const navigate = useNavigate()

  return (
    <div className={"header-container"}>
      <Row className={'header-wrapper'}>
        <Col className={'header-logo-wrapper'}>
          <Logo style={{ cursor: 'pointer' }} onClick={() => navigate('/')} />
        </Col>
        <Col className={'header-navigation-wrapper'}>
          {!isDesktop 
            ? <MobileNavigation menuItems={headerMenu as ComponentUiLink[]} />
            : <Navigation menuItems={headerMenu as ComponentUiLink[]}/>    
          }
        </Col>

        {isDesktop && <Col className={'header-right-side-items'}>
          <LanguageSelector />
          
          <div className={'header-socials-wrapper'}>
            <WhatsAppLogo />
          </div>
        </Col>}
      </Row>
    </div>
  )
}

export { Header }
